<template>
    <v-tooltip bottom max-width="400" color="#e3a76f">
        <template v-slot:activator="{ on }">
            <v-icon @mouseover="tooltip = true" @mouseleave="tooltip = false" v-on="on" color="#e3a76f">mdi-help-circle-outline</v-icon>
        </template>
            <span style="color: #384048;">{{ tooltipText }}</span>
    </v-tooltip>
</template>

<script>
export default {
    data() {
        return {
            tooltip: false,
        };
    },
    props: {
        tooltipText: {
            type: String,
            required: true,
        }
    },
};
</script>
